import React from 'react';
import { Hero } from '../Components/Hero';

export const Home = () => {
  return (
    <>
      <div className='flex flex-col h-screen bg'>
        <Hero />
      </div>
    </>
  )
}
